/** @jsx jsx */
import { jsx } from "theme-ui"

const Logo = (props) => (
  <img src="/logo.png" width={props.size} />
)

Logo.defaultProps = {
  color: "white",
}

export default Logo
